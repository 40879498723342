import { Button } from "@/components/ui/Button";
import { useLocalStorage } from "@/hooks/useStorage";
import Image from "next/image";
import { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";

const DownloadPWA = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [showPWABanner, setShowPWABanner, removeName] = useLocalStorage(
    "showPWABanner",
    true
  );

  useEffect(() => {
    if (!showPWABanner) return;
    const handler = e => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        setIsInstallable(false);
      }
      setDeferredPrompt(null);
    }
  };

  return showPWABanner && isInstallable ? (
    <div className="bg-primary w-full">
      <div className="h-[36px] mx-auto px-4 md:px-8 max-w-7xl flex items-center max-[400px]:justify-center justify-between bg-primary w-full text-primary-foreground">
        <div className="overflow-hidden h-full max-[400px]:hidden">
          <Image
            src="/images/tech-devices.png"
            width={80}
            height={80}
            alt="tech devices"
            objectFit="cover"
            className="!-mt-3"
          />
        </div>

        <span>
          إكتشف تطبيقنا الآن ، متوفر
          <Button
            variant="link"
            className="px-2 underline h-fit py-0 text-primary-foreground"
            onClick={handleInstallClick}
          >
            من هنا
          </Button>
        </span>

        <Button
          onClick={() => setShowPWABanner(false)}
          variant="ghost"
          className="rounded-full h-7 w-7 p-0 ms-4"
        >
          <IoClose className="text-[1.5rem]" />
        </Button>
      </div>
    </div>
  ) : null;
};

export default DownloadPWA;
