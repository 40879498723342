import { AiOutlinePercentage } from "react-icons/ai";
import { BiDollar, BiTransferAlt } from "react-icons/bi";
import { BsBoxes } from "react-icons/bs";
import { GrServices } from "react-icons/gr";
import { HiOutlineChartPie } from "react-icons/hi";
import { HiOutlineArrowTrendingUp } from "react-icons/hi2";
import { LiaHandshake } from "react-icons/lia";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { PiUsers } from "react-icons/pi";
import { RiContactsBook2Line, RiFileListLine } from "react-icons/ri";
import { TbPhone, TbSpeakerphone } from "react-icons/tb";
import { VscTools } from "react-icons/vsc";

export const privateRoutes = [
  {
    name: {
      ar: "الرئيسية",
      en: "dashboard",
    },
    path: "/dashboard",
    icon: <MdOutlineSpaceDashboard />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: {
      ar: "فليكسي جملة",
      en: "flexygros",
    },
    path: "/flexygros",
    icon: <TbPhone />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  // {
  //   name: {
  //     ar: "إيداع",
  //     en: "deposit",
  //   },
  //   path: "/deposit",
  //   icon: <MdOutlineAddCircleOutline />,
  //   roles: [],
  // },
  {
    name: {
      ar: "المستخدمين",
      en: "users1",
    },
    path: "/users1",
    icon: <PiUsers />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_MANAGER"],
  },
  {
    name: {
      ar: "التحويلات",
      en: "transactions",
    },
    path: "/transactions",
    icon: <BiTransferAlt />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: {
      ar: "الطلبات",
      en: "orders",
    },
    path: "/orders",
    icon: <RiFileListLine />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: {
      ar: "نسب الربح",
      en: "percentage",
    },
    path: "/percentage",
    icon: <AiOutlinePercentage />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: {
      ar: "جهات الإتصال",
      en: "contacts",
    },
    path: "/contacts",
    icon: <RiContactsBook2Line />,
    roles: ["ROLE_ADMIN", "ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },

  {
    name: {
      ar: "المخزن",
      en: "storage",
    },
    path: "/storage",
    icon: <BsBoxes />,
    roles: ["ROLE_ADMIN", "ROLE_MANAGER"],
  },
  {
    name: {
      ar: "إحصائيات",
      en: "statistics",
    },
    path: "/statistics",
    icon: <HiOutlineArrowTrendingUp />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: {
      ar: "الفليكسي",
      en: "flexy",
    },
    path: "/flexy",
    icon: <RiContactsBook2Line />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: {
      ar: "الإعلانات",
      en: "publicities",
    },
    path: "/publicities",
    icon: <TbSpeakerphone />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: {
      ar: "الدعم",
      en: "tickets",
    },
    path: "/tickets",
    icon: <LiaHandshake />,
    roles: [
      "ROLE_ADMIN",
      "ROLE_SUPER_GROS",
      "ROLE_GROS",
      "ROLE_MANAGER",
      "ROLE_DETAILS",
    ],
  },
  {
    name: {
      ar: "الأرباح",
      en: "profit",
    },
    path: "/profit",
    icon: <BiDollar />,
    roles: ["ROLE_SUPER_GROS", "ROLE_GROS", "ROLE_DETAILS"],
  },
  {
    name: {
      ar: "المداخيل",
      en: "income",
    },
    path: "/income",
    icon: <HiOutlineChartPie />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: {
      ar: "أدوات",
      en: "tools",
    },
    path: "/tools",
    icon: <VscTools />,
    roles: ["ROLE_ADMIN", "ROLE_MANAGER"],
  },
  {
    name: {
      ar: "الصيانة",
      en: "maintenance",
    },
    path: "/maintenance",
    icon: <GrServices />,
    roles: ["ROLE_ADMIN"],
  },
  {
    name: {
      ar: "إعدادات الحماية",
      en: "security",
    },
    path: "/security",
    icon: null,
    roles: [
      "ROLE_ADMIN",
      "ROLE_MANAGER",
      "ROLE_SUPER_GROS",
      "ROLE_GROS",
      "ROLE_DETAILS",
    ],
  },
  {
    name: {
      ar: "إعدادات الحساب",
      en: "profile",
    },
    path: "/profile",
    icon: null,
    roles: [
      "ROLE_ADMIN",
      "ROLE_MANAGER",
      "ROLE_SUPER_GROS",
      "ROLE_GROS",
      "ROLE_DETAILS",
    ],
  },
];
