import { cn } from "@/lib/utils";
import { RiArrowLeftSLine } from "react-icons/ri";

const ShrinkButton = ({ className, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "w-[29px] h-[29px] z-[101] hidden md:grid absolute place-items-center bg-bg-primary border rounded-[50%] top-[50px] left-[-14.5px] transition-[transform,opacity] duration-300 ease-in-out bg-background hover:text-accent-foreground hover:bg-accent",
        className
      )}
    >
      <RiArrowLeftSLine className="text-[1.2rem]" />
    </button>
  );
};

export default ShrinkButton;
