import dayjs from "dayjs";
import Axios from "./api";

const users = (set, get) => ({
  users: {
    isLoadingCurrentUser: false,
    currentUser: null,
    currentAvatar: null,
    clientNumber: null,
    missingInformation: null,
    statusChangeLoading: { loading: false, id: null },
    deleteLoading: { loading: false, id: null },
    selectedDuration: 24,
    isAllUsersTab: true,
    anyUsers: {
      users: null,
      content: null,
      loading: false,
    },
    subUsers: {
      users: null,
      content: null,
      loading: false,
      query: { page: 0, size: 10, sort: "createTime,desc" },
    },
    anyUser: {
      user: null,
      account: null,
      loading: false,
      superUser: null,
    },
    subUser: {
      users: null,
      content: null,
      loading: false,
    },
    orders: {
      orders: null,
      query: { page: 0, size: 10, sort: "createTime,desc" },
      content: null,
      loading: false,
    },
    transactions: {
      transactions: null,
      query: { page: 0, size: 10, sort: "createTime,desc" },
      content: null,
      loading: false,
    },
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },
  setSelectedDuration: newDuration => {
    set({
      users: {
        ...get().users,
        selectedDuration: newDuration,
      },
    });
  },

  setIsAllUsersTab: newValue => {
    set({
      users: {
        ...get().users,
        isAllUsersTab: newValue,
      },
    });
  },

  resetSubUsersQuery: () => {
    set({
      users: {
        ...get().users,
        subUser: {
          ...get().users.subUsers,
          query: { page: 0, size: 10, sort: "createTime,desc" },
        },
      },
    });
  },
  setSubUsersQuery: query => {
    set({
      users: {
        ...get().users,
        subUsers: {
          ...get().users.subUsers,
          query: { ...get().users.subUsers.query, ...query },
        },
      },
    });
  },
  resetUsersQuery: () => {
    set({
      users: {
        ...get().users,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setUsersQuery: query => {
    set({
      users: {
        ...get().users,
        query: { ...get().users.query, ...query },
      },
    });
  },
  resetUserTransQuery: () => {
    set({
      users: {
        ...get().users,
        transactions: {
          ...get().users.transactions,
          query: { page: 0, size: 10, sort: "createTime,desc" },
        },
      },
    });
  },
  setUserTransQuery: query => {
    set({
      users: {
        ...get().users,
        transactions: {
          ...get().users.transactions,
          query: { ...get().users.transactions.query, ...query },
        },
      },
    });
  },
  resetUserOrdersQuery: () => {
    set({
      users: {
        ...get().users,
        orders: {
          ...get().users.orders,
          query: { page: 0, size: 10, sort: "createTime,desc" },
        },
      },
    });
  },
  setUserOrdersQuery: query => {
    set({
      users: {
        ...get().users,
        orders: {
          ...get().users.orders,
          query: { ...get().users.orders.query, ...query },
        },
      },
    });
  },
  createSubUser: async values => {
    try {
      const res = await Axios.post(`/users`, values);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  addManager: async values => {
    try {
      const res = await Axios.post(`/users/addManager`, values);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  uploadAvatar: async form => {
    try {
      const res = await Axios.post(`/dashboard/uploadAvatar`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },

  updateAnyUser: async (values, id) => {
    try {
      const res = await Axios.put(`/users/${id}`, values);
      await get().getAllSubUsers();
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  updateSubUser: async (values, id) => {
    try {
      const res = await Axios.put(`/users/sub/${id}`, values);

      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },

  editeCurrentUser: async values => {
    try {
      const res = await Axios.put(`/settings/update`, values);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  deleteAnyUser: async id => {
    set({
      users: { ...get().users, deleteLoading: { loading: true, id } },
    });

    try {
      const res = await Axios.delete(`/users/${id}`);
      await get().getAllSubUsers();
      set({ users: { ...get().users, deleteLoading: { loading: false, id } } });

      return res;
    } catch (error) {
      set({ users: { ...get().users, deleteLoading: { loading: false, id } } });

      //console.log(error.response);
      return error.response;
    }
  },
  deleteSubUser: async (id, reason) => {
    set({ users: { ...get().users, deleteLoading: { loading: true, id } } });

    try {
      const res = await Axios.delete(`/users/sub/${id}`, { data: { reason } });
      await get().getAllSubUsers();
      set({ users: { ...get().users, deleteLoading: { loading: false, id } } });

      return res;
    } catch (error) {
      set({ users: { ...get().users, deleteLoading: { loading: false, id } } });

      //console.log(error.response);
      return error.response;
    }
  },
  getAnyUser: async (param, loading, byUsername = false) => {
    try {
      set({
        users: {
          ...get().users,
          anyUser: { ...get().anyUser, loading },
        },
      });

      let anyUserRes;
      let superUserRes;

      if (byUsername) {
        const [res1, res2] = await Promise.all([
          Axios.get(`/users/getByUsername?username=${param}`),
          Axios.get("/users/getSuperUser", { params: { username: param } }),
        ]);

        anyUserRes = res1;
        superUserRes = res2;
      } else {
        anyUserRes = await Axios.get(`/users/get/${param}`);
      }

      set({
        users: {
          ...get().users,
          anyUser: {
            ...get().anyUser,
            loading: false,
            user: anyUserRes.data.user,
            account: anyUserRes.data.account,
            superUser: superUserRes?.data?.username,
          },
        },
      });
    } catch (error) {
      set({
        users: {
          ...get().users,
          anyUser: { ...get().anyUser, loading: false },
        },
      });
    }
  },

  getSubUser: async (param, loading, byUsername = false) => {
    try {
      set({
        users: {
          ...get().users,
          subUser: { ...get().subUser, loading },
        },
      });
      const path = byUsername
        ? `/users/sub/getByUsername?username=${param}`
        : `/users/sub/${param}`;

      const res = await Axios.get(path);
      set({
        users: {
          ...get().users,
          subUser: {
            ...get().subUser,
            loading: false,
            user: res.data.user,
            account: res.data.account,
          },
        },
      });
      return res;
    } catch (error) {
      //console.log(error.response);
      set({
        users: {
          ...get().users,
          subUser: { ...get().subUser, loading: false },
        },
      });
    }
  },
  getAnyUserByUserName: async (username, loading = true) => {
    try {
      set({
        users: {
          ...get().users,
          anyUsers: { ...get().anyUsers, loading },
        },
      });
      const params = { username };
      const res = await Axios.get(`/users/getUser`, { params });
      const users = res.data;
      set({
        users: {
          ...get().users,
          anyUsers: {
            ...get().anyUsers,
            loading: false,
            users: [...users],
            // account: res.data.account,
          },
        },
      });
      return res;
    } catch (error) {
      //console.log(error.response);
      set({
        users: {
          ...get().users,
          anyUsers: { ...get().anyUsers, loading: false },
        },
      });
      return error.response;
    }
  },
  getSubUserByUserName: async (username, loading = true) => {
    try {
      set({
        users: {
          ...get().users,
          anyUsers: { ...get().anyUsers, loading },
        },
      });
      const params = { username };
      const res = await Axios.get(`/users/sub/getUser`, { params });
      const users = res.data;

      set({
        users: {
          ...get().users,
          anyUsers: {
            ...get().anyUsers,
            loading: false,
            users: [...users],
            // account: res.data.account,
          },
        },
      });
      return res;
    } catch (error) {
      //console.log(error.response);
      set({
        users: {
          ...get().users,
          anyUsers: { ...get().anyUsers, loading: false },
        },
      });
      return error.response;
    }
  },

  getAllSubUsers: async () => {
    try {
      set({
        users: {
          ...get().users,
          anyUsers: {
            ...get().anyUsers,
            loading: true,
          },
        },
      });

      const isAll = get().users.isAllUsersTab;
      let selectedDuration = get().users.selectedDuration;

      if (!isAll) {
        const now = new Date(); // Current date and time
        const fortyEightHoursAgo = new Date(
          now.getTime() - selectedDuration * 60 * 60 * 1000
        ); // Subtract 48 hours

        // Format the date using dayjs to match the desired format "YYYY-MM-DD+HH:mm:ss"
        selectedDuration = dayjs(fortyEightHoursAgo).format(
          "YYYY-MM-DD+HH:mm:ss"
        );
      }

      const params = {
        ...get().users.query,
        sort: `${isAll ? "createTime" : "lastActivity"},desc`,
      };

      const path = isAll
        ? "/users"
        : "/distributorsStats/inActiveUsers?duration=" + selectedDuration;

      const res = await Axios.get(path, { params });
      if (res.status === 200) {
        set({
          users: {
            ...get().users,
            anyUsers: {
              ...get().anyUsers,
              content: res.data,
              loading: false,
              users: res.data?.content,
            },
          },
        });
      }
    } catch (error) {
      set({
        users: {
          ...get().users,
          anyUsers: {
            ...get().anyUsers,

            loading: false,
          },
        },
      });
      //console.log(error.response);
    }
  },

  getAllSubUsersOfUser: async (loading = true, username) => {
    try {
      set({
        users: {
          ...get().users,
          subUsers: {
            ...get().users.subUsers,
            loading,
          },
        },
      });
      const params = {
        ...get().users.subUsers.query,
      };

      const res = await Axios.get(`/users/username/${username}`, { params });

      if (res.status === 200) {
        set({
          users: {
            ...get().users,
            subUsers: {
              ...get().users.subUsers,
              content: res.data,
              loading: false,
              users: res.data?.content,
            },
          },
        });
      }
    } catch (error) {
      set({
        users: {
          ...get().users,
          subUsers: {
            ...get().subUsers,

            loading: false,
          },
        },
      });
    }
  },
  getCurrentUser: async () => {
    try {
      const res = await Axios.get(`/dashboard/currentUser`);
      if (res.status === 200) {
        set({
          currentUser: res.data,
        });
      }
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },

  fetchCurrentUserData: async () => {
    try {
      set({
        users: {
          ...get().users,
          isLoadingCurrentUser: true,
        },
      });

      const res = await Axios.get(`/dashboard/currentUser`);
      set({
        currentUser: res.data,
      });
    } catch (error) {
      throw error;
    } finally {
      set({
        users: {
          ...get().users,
          isLoadingCurrentUser: false,
        },
      });
    }
  },

  resetCurrentUser: () => {
    set({
      currentUser: null,
    });
  },

  getAccountInfo: async userId => {
    try {
      const res = await Axios.get(`/users/sub/accountInfo/${userId}`);
      return res;
    } catch (error) {
      // console.log("error", error.response);
    }
  },
  getMissingInformation: async () => {
    try {
      const res = await Axios.get(`/dashboard/missingInformation`);
      if (res.status === 200) {
        set({
          missingInformation: res.data,
        });
      }
    } catch (error) {
      return error.response;
    }
  },

  getClientNumber: async () => {
    try {
      const res = await Axios.get(`/users/sub/clientsNumber`);
      if (res.status === 200) {
        set({
          clientNumber: res.data,
        });
      }
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  disableOrEnableAnyUser: async (id, sub) => {
    set({
      user: { ...get().users, statusChangeLoading: { loading: true, id } },
    });

    try {
      const res = await Axios.post(`/users/disable/${id}`);
      if (sub) {
        await get().getAllSubUsersOfUser(true, sub);
      } else {
        await get().getAllSubUsers();
      }
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });
      return res;
    } catch (error) {
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });

      //console.log(error.response);
      return error.response;
    }
  },
  disableOrEnableSubUser: async id => {
    set({
      user: { ...get().users, statusChangeLoading: { loading: true, id } },
    });

    try {
      const res = await Axios.post(`/users/sub/disable/${id}`);

      await get().getAllSubUsers();

      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });
      return res;
    } catch (error) {
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });

      //console.log(error.response);
      return error.response;
    }
  },

  resetPassword: async values => {
    try {
      const res = await Axios.post("/settings/updatePassword", {
        oldPassword: values.oldPassword,
        password: values.password,
      });

      return res;
    } catch (error) {
      return error.response;
      //console.log(error.response);
    }
  },
  changePassword: async (userId, password) => {
    try {
      password = password?.password;
      const res = await Axios.patch(`/users/newPassword/${userId}`, {
        password: password,
      });
      return res;
    } catch (error) {
      return error.response;
      //console.log(error.response);
    }
  },

  changePhoneByAdmin: async (userId, phone) => {
    try {
      const res = await Axios.patch(`/manage/changePhone/${userId}`, {
        phone,
      });
      return res;
    } catch (error) {
      return error.response;
    }
  },

  changeEmailByAdmin: async (userId, email) => {
    try {
      const res = await Axios.patch(`/manage/changeEmail/${userId}`, {
        email,
      });
      return res;
    } catch (error) {
      return error.response;
    }
  },
  // AutoCharge Functions
  enableOrDisableAutoCharge: async id => {
    set({
      user: { ...get().users, statusChangeLoading: { loading: true, id } },
    });

    try {
      const res = await Axios.post(`/users/sub/enableAutoCharge/${id}`);
      await get().getAllSubUsers();
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });
      return res;
    } catch (error) {
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });
    }
  },

  settingAutoCharge: async (values, userId) => {
    try {
      const res = await Axios.post(
        `/users/sub/settingAutoCharge/${userId}?max=${values.max}&maxChargeAmount=${values.maxChargeAmount}&min=${values.min}`,
        values
      );
      return res;
    } catch (error) {
      return error.response;
    }
  },

  disableDebtTrackingForUser: async id => {
    set({
      user: { ...get().users, statusChangeLoading: { loading: true, id } },
    });

    try {
      const res = await Axios.post("/debtTracking/disable", null, {
        params: {
          userId: id,
        },
      });
      await get().getAllSubUsers();
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });
      return res;
    } catch (error) {
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });
      throw error;
    }
  },

  settingDebtTrackingForUser: async (days, userId) => {
    set({
      user: {
        ...get().users,
        statusChangeLoading: { loading: true, id: userId },
      },
    });

    try {
      const res = await Axios.post("/debtTracking", null, {
        params: {
          days,
          userId,
        },
      });

      await get().getAllSubUsers();
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });

      return res;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  },

  settingDebtTrackingForAllUsers: async days => {
    set({
      user: { ...get().users, statusChangeLoading: { loading: true, id } },
    });

    try {
      const res = await Axios.post("/debtTracking/all", null, {
        params: {
          days,
        },
      });
      await get().getAllSubUsers();
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });
      return res;
    } catch (error) {
      throw error;
    }
  },

  getDebtTrackForUser: async userId => {
    try {
      const res = await Axios.get("/debtTracking", {
        params: {
          userId,
        },
      });
      return res;
    } catch (error) {
      return error.response;
    }
  },

  toggleFrozenAccount: async userId => {
    set({
      user: {
        ...get().users,
        statusChangeLoading: { loading: true, id: userId },
      },
    });

    try {
      const res = await Axios.post("/accounts/toggle", null, {
        params: {
          userId,
        },
      });

      await get().getAllSubUsers();
      set({
        user: {
          ...get().users,
          statusChangeLoading: { loading: false, id: null },
        },
      });

      return res;
    } catch (error) {
      throw error;
    }
  },
});

export default users;
