import dayjs from "dayjs";
import "dayjs/locale/ar";
import relativeTime from "dayjs/plugin/relativeTime";
import Link from "next/link";
import { BiSupport, BiTransferAlt } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { FaPercentage } from "react-icons/fa";

dayjs.locale("ar");
dayjs.extend(relativeTime);

const icons = {
  1: BiSupport,
  2: BiTransferAlt,
  3: FaPercentage,
  4: BsInfoCircle,
};

const NotificationItem = ({ notification, onClick }) => {
  const { content, createTime, type, ticket } = notification;

  const handleRef = (type, ticket) => {
    return type === 1 ? `/tickets/${ticket?.id}` : "/dashboard";
  };

  const Icon = icons[type];

  return (
    <Link passHref href={handleRef(type, ticket)}>
      <a onClick={onClick} className="w-full flex gap-3 p-3">
        <div className="p-3 bg-muted text-foreground rounded-xl self-start">
          <Icon className="text-[1.2rem]" />
        </div>

        <span className="text-sm mt-1">
          <span className="me-3">{content}</span>
          <span className="text-muted-foreground text-xs inline-block">
            {dayjs(createTime).fromNow()}
          </span>
        </span>
      </a>
    </Link>
  );
};

export default NotificationItem;
