import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/DropdownMenu";
import { RxMoon } from "react-icons/rx";
import { FiSun } from "react-icons/fi";
import { BsLaptop } from "react-icons/bs";
import { Button } from "@/ui/Button";
import { useTheme } from "next-themes";
import { useColorMode } from "@chakra-ui/react";

const ThemeToggle = ({}) => {
  const { setTheme } = useTheme();
  const { setColorMode } = useColorMode();

  const handleChangeColorMode = mode => {
    setTheme(mode);
    setColorMode(mode);
  };

  return (
    <DropdownMenu dir="rtl">
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="text-[1.3rem] rounded-full p-0 h-9 w-9 relative"
        >
          <FiSun className="rotate-0 scale-100 transition-all dark:rotate-90 dark:scale-0" />
          <RxMoon className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">تغيير الوصع</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-[99]" align="end" forceMount>
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => handleChangeColorMode("light")}
        >
          <FiSun className="mx-2 h-4 w-4" />
          <span>فاتح</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => handleChangeColorMode("dark")}
        >
          <RxMoon className="mx-2 h-4 w-4" />
          <span>داكن</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => handleChangeColorMode("system")}
        >
          <BsLaptop className="mx-2 h-4 w-4" />
          <span>نظام</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ThemeToggle;
