import { privateRoutes } from "@/data/privateRoutes";
import Paragraph from "@/ui/Paragraph";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { MobileSidebar } from "../sidebar/MobileSidebar";
import NotificationsMenu from "./NotificationsMenu";
import ThemeToggle from "./ThemeToggle";
import DownloadPWA from "./DownloadPWA";

const Navbar = () => {
  const { pathname } = useRouter();
  const basePath = "/" + pathname.split("/")[1];

  const title = useMemo(
    () => privateRoutes.find(route => route.path === basePath),
    [basePath]
  )?.name?.ar;

  return (
    <header className="sticky top-0 z-30 md:static">
      <DownloadPWA />
      <div className="flex justify-between h-14 items-center gap-4 border-b bg-background p-4 md:pb-0 px-4 md:h-auto md:border-0 md:bg-transparent md:px-6">
        <div className="flex items-center gap-2">
          <MobileSidebar />

          <Paragraph variant="subTitle">{title}</Paragraph>
        </div>

        <div className="flex items-center gap-2">
          <NotificationsMenu />
          <ThemeToggle />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
