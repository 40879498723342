import Axios from "./api";

const sessions = (set, get) => ({
  connectedDevices: {
    content: null,
    isLoading: false,

    getConnectedDevices: async () => {
      try {
        set({
          connectedDevices: {
            ...get().connectedDevices,
            isLoading: true,
          },
        });

        const res = await Axios.get("/sessions");

        set({
          connectedDevices: {
            ...get().connectedDevices,
            content: res.data,
          },
        });
      } catch (error) {
      } finally {
        set({
          connectedDevices: {
            ...get().connectedDevices,
            isLoading: false,
          },
        });
      }
    },

    deleteAllSessions: async () => {
      try {
        set({
          connectedDevices: {
            ...get().connectedDevices,
            isLoading: true,
          },
        });

        await Axios.delete("/sessions");

        set({
          connectedDevices: {
            ...get().connectedDevices,
            content: [],
          },
        });

        window.location.href = "/logout";
      } catch (error) {
      } finally {
        set({
          connectedDevices: {
            ...get().connectedDevices,
            isLoading: false,
          },
        });
      }
    },

    deleteSession: async id => {
      try {
        set({
          connectedDevices: {
            ...get().connectedDevices,
            isLoading: true,
          },
        });

        await Axios.delete("/sessions/one", { params: { id } });
        const filteredDevices = await Axios.get("/sessions");


        set({
          connectedDevices: {
            ...get().connectedDevices,
            content: filteredDevices.data,
          },
        });
      } catch (error) {
        console.log({ error });
      } finally {
        set({
          connectedDevices: {
            ...get().connectedDevices,
            isLoading: false,
          },
        });
      }
    },
  },
});

export default sessions;
