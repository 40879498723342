import Axios from "./api";

const admintickets = (set, get) => ({
  adminTickets: {
    adminTickets: null,
    ticket: null,
    content: null,
    loading: false,
    singleLoading: false,
    editLoading: false,
    deleteLoading: false,
    addLoading: false,
    deleteReplyLoading: false,
    statusLoading: false,
    query: { page: 0, size: 10, sort: "createTime,desc" },
    isGetType: false,
    type: null,
    status: null,
  },
  setType: type => {
    set({
      adminTickets: {
        ...get().adminTickets,
        type,
      },
    });
  },
  setStatus: status => {
    set({
      adminTickets: {
        ...get().adminTickets,
        status,
      },
    });
  },
  toggleGetType: isGetType => {
    set({
      adminTickets: {
        ...get().adminTickets,
        isGetType,
      },
    });
  },
  resetAdminTicketQuery: () => {
    set({
      adminTickets: {
        ...get().adminTickets,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setAdminTicketQuery: query => {
    set({
      adminTickets: {
        ...get().adminTickets,
        query: { ...get().adminTickets.query, ...query },
      },
    });
  },
  getAllTickets: async loading => {
    get().toggleGetType(false);
    try {
      set({
        adminTickets: { ...get().adminTickets, loading: true },
      });
      const params = {
        ...get().adminTickets.query,
      };
      const res = await Axios.get(`/support/all`, { params });
      //console.log('adminTickets', res);
      if (res.status === 200) {
        set({
          adminTickets: {
            ...get().adminTickets,
            content: res.data,
            loading: false,
            adminTickets: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        adminTickets: { ...get().adminTickets, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  getAllTicketsByType: async (loading, type) => {
    get().toggleGetType(true);

    try {
      set({
        adminTickets: { ...get().adminTickets, loading: true },
      });
      const params = {
        ...get().adminTickets.query,
      };
      const res = await Axios.get(`/support/type?type=${type}`, { params });
      //console.log('adminTickets', res);
      if (res.status === 200) {
        set({
          adminTickets: {
            ...get().adminTickets,
            content: res.data,
            loading: false,
            adminTickets: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        adminTickets: { ...get().adminTickets, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  getAllTicketsByUsername: async (loading, username) => {
    set({
      adminTickets: { ...get().adminTickets, loading: true },
    });
    try {
      const params = {
        ...get().adminTickets.query,
      };
      const res = await Axios.get(`/support/username?username=${username}`, {
        params,
      });
      if (res.status === 200) {
        set({
          adminTickets: {
            ...get().adminTickets,
            content: res.data,
            loading: false,
            adminTickets: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        adminTickets: { ...get().adminTickets, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  getTicket: async id => {
    try {
      set({
        adminTickets: { ...get().adminTickets, singleLoading: true },
      });
      const res = await Axios.get(`/support/ticket?ticketId=${id}`);
      //console.log('adminTickets', res);
      if (res.status === 200) {
        set({
          adminTickets: {
            ...get().adminTickets,
            singleLoading: false,
            ticket: res.data,
          },
        });
      }
    } catch (error) {
      set({
        adminTickets: { ...get().adminTickets, singleLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },

  addReply: async (id, content) => {
    set({
      adminTickets: { ...get().adminTickets, addLoading: true },
    });
    try {
      const res = await Axios.post(
        `/support/ticket?ticketId=${id}&content=${content}`
      );
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  deleteTicket: async id => {
    set({
      adminTickets: { ...get().adminTickets, deleteLoading: true },
    });
    try {
      const res = await Axios.delete(`/support/${id}`);
      set({
        adminTickets: { ...get().adminTickets, deleteLoading: false },
      });
      return res;
    } catch (error) {
      set({
        adminTickets: { ...get().adminTickets, deleteLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  deleteReply: async id => {
    set({
      adminTickets: { ...get().adminTickets, deleteReplyLoading: true },
    });
    try {
      const res = await Axios.delete(`/support/ticket/${id}`);
      set({
        adminTickets: { ...get().adminTickets, deleteReplyLoading: false },
      });
      return res;
    } catch (error) {
      set({
        adminTickets: { ...get().adminTickets, deleteReplyLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  changeTicketStatus: async (id, status) => {
    set({
      adminTickets: { ...get().adminTickets, statusLoading: true },
    });
    try {
      const res = await Axios.patch(
        `/support/ticket?ticketId=${id}&status=${status}`
      );
      set({
        adminTickets: { ...get().adminTickets, statusLoading: false },
      });
      return res;
    } catch (error) {
      set({
        adminTickets: { ...get().adminTickets, statusLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  getAllTicketsByStatus: async status => {
    get().toggleGetType(true);

    set({
      adminTickets: { ...get().adminTickets, loading: true },
    });

    try {
      const params = {
        ...get().adminTickets.query,
      };
      const res = await Axios.get(`/support/status?status=${status}`, {
        params,
      });
      if (res.status === 200) {
        set({
          adminTickets: {
            ...get().adminTickets,
            content: res.data,
            loading: false,
            adminTickets: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        adminTickets: { ...get().adminTickets, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
});

export default admintickets;
