import { Box, ChakraProvider } from "@chakra-ui/react";
import { DirectionProvider } from "@radix-ui/react-direction";
import { RtlProvider } from "components/layout/rtl-provider";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import theme from "theme";
import { Toaster } from "./ui/Sonner";

function Providers({ children, Component }) {
  return (
    <ChakraProvider theme={theme}>
      <NextThemesProvider
        forcedTheme={Component.theme || null}
        attribute="class"
        defaultTheme="system"
        enableSystem
      >
        <DirectionProvider dir="rtl">
          <RtlProvider>
            {children}
            <Toaster />
          </RtlProvider>
        </DirectionProvider>
      </NextThemesProvider>
    </ChakraProvider>
  );
}

export default Providers;
