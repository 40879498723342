import { cn } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";
import Content from "./Content";
import ShrinkButton from "./ShrinkButton";
import useHover from "@/hooks/useHover";

const Sidebar = ({ isShrunk, setIsShrunk }) => {
  const sidebarRef = useRef();
  const hovered = useHover(sidebarRef);
  const [isHovered, setIsHovered] = useState(hovered);
  const isSmall = isShrunk && !isHovered;

  useEffect(() => {
    if (isShrunk || isHovered) {
      setIsHovered(hovered);
    }
  }, [hovered]);

  return (
    <nav
      ref={sidebarRef}
      className={cn(
        "w-[290px] bg-background max-w-full z-10 h-[100dvh] hidden md:block fixed top-0 border-l transition-[width] duration-300 ease-in-out",
        isSmall && "w-[68px] !z-[100]",
        isHovered && "!z-[100]"
      )}
    >
      <ShrinkButton
        onClick={() => setIsShrunk(prev => !prev)}
        className={cn(
          isShrunk ? "rotate-0" : "rotate-[180deg]",
          hovered ? "opacity-1" : "opacity-0"
        )}
      />

      <Content isSmall={isSmall} />
    </nav>
  );
};

export default Sidebar;
