import { cva } from "class-variance-authority";
import * as React from "react";
import { LuLoader2 } from "react-icons/lu";
import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "active:scale-95 inline-flex items-center gap-2 justify-center rounded-md text-sm font-medium transition-colors ring-offset-background data-[state=open]:outline-none data-[state=open]:ring-2 data-[state=open]:ring-ring data-[state=open]:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary: "bg-primary text-primary-foreground hover:bg-primary/90",
        link: "bg-transparent underline-offset-4 hover:underline text-primary hover:bg-transparent",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        icon: "border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 w-10 !px-2",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  (
    {
      className,
      isLoading,
      children,
      variant,
      size,
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <button
        className={cn(buttonVariants({ size, className, variant }))}
        ref={ref}
        {...props}
        disabled={disabled || isLoading}
      >
        {children}
        {isLoading ? <LuLoader2 className="animate-spin w-4 h-4" /> : null}
      </button>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
