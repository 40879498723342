import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

import percentage from "./percentage";
import transactions from "./transactions";
import orders from "./orders";
import ordersFlexyGros from "./ordersFlexyGros";
import auth from "./auth";
import users from "./users";
import util from "./util";
import categories from "./categories";
import subCategories from "./subCategories";
import products from "./products";
import contacts from "./contacts";
import clienttickets from "./clienttickets";
import admintickets from "./admintickets";
import client from "./client";
import upload from "./upload";
import publicity from "./publicity";
import flexy from "./flexy";
import profit from "./profit";
import statistic from "./statistic";
import notifications from "./notification";
import tools from "./tools";
import maintenance from "./maintenace";
import deposit from "./deposit";
import sessions from "./sessions";
import settings from "./settings";

const useStore = create(
  devtools(
    persist(
      (set, get) => ({
        showMoneyCards: true,
        toggleShowMoneyCards: () =>
          set({ showMoneyCards: !get().showMoneyCards }),
        showLastOrders: true,
        toggleShowLastOrders: () =>
          set({ showLastOrders: !get().showLastOrders }),
        showPubs: true,
        toggleShowPubs: () => set({ showPubs: !get().showPubs }),
        deleteEverything: () => {
          const initialState = {
            ...auth(set, get),
            ...users(set, get),
          };

          set(initialState);
        }, // clears the entire store except the initialState, actions included
        ...percentage(set, get),
        ...sessions(set, get),
        ...transactions(set, get),
        ...orders(set, get),
        ...ordersFlexyGros(set, get),
        ...auth(set, get),
        ...notifications(set, get),
        ...users(set, get),
        ...util(set, get),
        ...categories(set, get),
        ...subCategories(set, get),
        ...products(set, get),
        ...contacts(set, get),
        ...admintickets(set, get),
        ...clienttickets(set, get),
        ...client(set, get),
        ...upload(set, get),
        ...publicity(set, get),
        ...flexy(set, get),
        ...profit(set, get),
        ...statistic(set, get),
        ...tools(set, get),
        ...maintenance(set, get),
        ...deposit(set, get),
        ...settings(set, get),
      }),
      {
        name: "dh",
        getStorage: () => localStorage,
        whitelist: [
          "auth",
          "isLoggedIn",
          "isAccountActivate",
          "currentUser",
          "showMoneyCards",
          "showLastOrders",
          "showPubs",
        ],
        partialize: state => ({
          auth: state.auth,
          isLoggedIn: state.isLoggedIn,
          isAccountActivate: state.isAccountActivate,
          currentUser: state.currentUser,
          showMoneyCards: state.showMoneyCards,
          showLastOrders: state.showLastOrders,
          showPubs: state.showPubs,
          isPhoneVerified: state.isPhoneVerified,
          isPinCreated: state.isPinCreated,
          isLocationAdded: state.isLocationAdded,

        }),
      }
    )
  )
);

export default useStore;
